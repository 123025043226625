export const ReturnPolicy: React.FC = () => {
  return (
    <>
      <p className="mb-8">
        ShopHelp and all of its partnered stores share the same return policies to provide
        consistent unique guarantee service to our customers. When you order an item directly from
        ShopHelp or from any of its’ stores, you are entitled to ShopHelp Return Policies.
      </p>
      <p className="mb-6 pt-4">
        ShopHelp offers a 7-day quality guarantee for damaged/faulty item(s). Customers can return
        defective item(s) for a refund, replacement, or exchange. Requests older than 7 days from
        delivery date will not be accepted.
      </p>
      <p className="mb-6">
        For non-quality related issues such as no longer wanting the item, ordering the wrong size
        or color, we&apos;re happy to offer size/color exchanges within 7 days of order delivery
        date. If you&apos;d like to swap an item for another size or color, simply contact us to
        request the exchange.
      </p>
      <p className="mb-6">
        Returned item(s) must be unwashed, unworn, unused, and in brand new condition with tags.
      </p>
      <p className="mb-6">
        Exchange requests must be for products with the same or lower value only.
      </p>
      <p className="mb-6">
        We do not provide pre-paid return labels; you are responsible for covering shipping costs to
        return <u>except in cases where the item is confirmed to be faulty by our team.</u>
      </p>
      <p className="mb-8">
        You will be notified via email about your return status. If you are eligible for a refund,
        the refund amount will be credited to your original payment method within 3-7 days.{' '}
        <u>Please note the original shipping charge is non-refundable.</u>
      </p>
      <p className="mb-6 pt-4 has-text-weight-semibold">Reserved Rights Regarding Returns</p>
      <p>
        ShopHelp reserves the right to solely define and limit, refuse, and/or reject returns from
        customers at any time due to:
      </p>
      <ul style={{ listStyle: 'disc', marginLeft: '1.5rem' }} className="mb-6">
        <li>An irregular or excessive returns history indicative of “wardrobing”;</li>
        <li>
          An irregular or excessive returns history involving worn, altered, laundered, damaged, or
          missing items; or,
        </li>
        <li>Potential fraudulent or criminal activity.</li>
      </ul>
      <p className="mb-8 pt-2 has-text-weight-semibold">Cancellations or changes:</p>
      <p className="mb-6">
        In most cases, orders are automatically processed within 6 hours from when they are placed.
        While we understand that orders might need to be changed sometimes, we cannot do it free of
        charge after a certain point. We strictly adhere to the following policy:
      </p>
      <p className="mb-6">
        If you need to cancel your order without incurring a fee, please{' '}
        <span className="has-text-weight-semibold">contact us</span> WITHIN 6 hours of order
        successfully paid.
      </p>
      <p className="mb-6">
        If you need to make changes to your order, please{' '}
        <span className="has-text-weight-semibold">contact us</span> WITHIN 24 hours of the order
        successfully paid.
      </p>
      <p className="mb-6">
        We do not allow order modifications AFTER 24 hours of successful payment. If you still wish
        to make changes, please <span className="has-text-weight-semibold">contact us</span> ASAP,
        and we will do what we can to help.
      </p>
    </>
  )
}
