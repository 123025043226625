import { AboutUs } from '@components/static-content/AboutUs'
import { PaymentMethods } from '@components/static-content/PaymentMethods'
import { ReturnPolicy } from '@components/static-content/ReturnPolicy'
import { ShippingPolicy } from '@components/static-content/ShippingPolicy'
import { TermOfService } from '@components/static-content/TermsOfService'
import { AppPolicyRaw } from '@components/static-content/AppPrivacyRawHTML'

export enum StaticContentConst {
  ABOUT_US = 'about-us',
  SHIPPING_POLICY = 'shipping-policy',
  RETURN_POLICY = 'return-policy',
  PAYMENT_METHODS = 'payment-methods',
  TERM_OF_SERVICE = 'terms-of-service',
  APP_PRIVACRY_POLICY = 'privacy-policy'
}

export const StaticContentComponents = {
  [StaticContentConst.ABOUT_US]: AboutUs,
  [StaticContentConst.PAYMENT_METHODS]: PaymentMethods,
  [StaticContentConst.RETURN_POLICY]: ReturnPolicy,
  [StaticContentConst.SHIPPING_POLICY]: ShippingPolicy,
  [StaticContentConst.TERM_OF_SERVICE]: TermOfService,
  [StaticContentConst.APP_PRIVACRY_POLICY]: AppPolicyRaw
}

export const StaticContentTitle = {
  [StaticContentConst.ABOUT_US]: 'About Us',
  [StaticContentConst.SHIPPING_POLICY]: 'Shipping Policy',
  [StaticContentConst.RETURN_POLICY]: 'Return & Replace Policy',
  [StaticContentConst.PAYMENT_METHODS]: 'Payment methods',
  [StaticContentConst.TERM_OF_SERVICE]: 'Term of services',
  [StaticContentConst.APP_PRIVACRY_POLICY]: 'App Privacy Policy'
}
