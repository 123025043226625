import css from 'styled-jsx/css'
import { StaticContentConst, StaticContentTitle } from '@components/static-content'
import { useRouter } from 'next/dist/client/router'
import { shopHelpPath } from '@/helpers'

const Footer: React.FC<{ className?: string }> = ({ className = '' }) => {
  const router = useRouter()
  const shopHelpPhone = '(877) 778-8353'
  return (
    <>
      <style jsx>{globalStyle}</style>
      <footer className={`footer shophelp-footer ${className}`}>
        <div className="shophelp-footer__go-top is-hidden-touch">
          <a
            className="shophelp-footer__go-top-button"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            Go to top
            <figure className="image is14x14">
              <img alt="to top" src={shopHelpPath(`arrow_up.svg`)} />
            </figure>
          </a>
        </div>

        <div className="container ">
          <div className="columns">
            <div className="column">
              <div className="group-title mb-4">Corporate office:</div>
              <p className="is-flex mb-2 has-text-white" style={{ alignItems: 'flex-start' }}>
                <img
                  alt="location"
                  src={shopHelpPath(`location.png`)}
                  className="image"
                  style={{ paddingTop: 2 }}
                />
                <span className="ml-2">
                  {/* Parlee McLaws LLP <br /> */}
                  1700, 10175 - 101 Street NW
                  <br /> Edmonton AB T5J 0H3
                </span>
              </p>
              {/*<a className="is-flex is-align-items-center mb-2" href="mailto:hello@shophelp.io">*/}
              {/*  <img*/}
              {/*    alt="email"*/}
              {/*    src={getImageDomainLink(`${getConfig().publicRuntimeConfig.cdn}/img/email.png`)}*/}
              {/*  />*/}
              {/*  <span className="ml-2">hello@shophelp.io</span>*/}
              {/*</a>*/}
              {/*<a className="is-flex is-align-items-center mb-2" href={`tel:(587) 412-1875`}>*/}
              {/*  <img*/}
              {/*    alt="phone"*/}
              {/*    src={getImageDomainLink(`${getConfig().publicRuntimeConfig.cdn}/img/phone.png`)}*/}
              {/*  />*/}
              {/*  <span className="ml-2">(587) 412-1875</span>*/}
              {/*</a>*/}
            </div>
            <div className="column">
              <div className="group-title mb-4">Customer Service Center:</div>
              <a
                className="is-flex is-align-items-center mb-2 has-text-white"
                href="mailto:support@shophelp.io"
              >
                <img alt="email" src={shopHelpPath('email.png')} />
                <span className="ml-2">support@shophelp.io</span>
              </a>
              <a
                className="is-flex is-align-items-center mb-2 has-text-white"
                href={`tel:+1 ${shopHelpPhone}`}
              >
                <img alt="phone" src={shopHelpPath(`phone.png`)} />
                <span className="ml-2">{shopHelpPhone}</span>
              </a>
              <p className="mb-2 is-italic">(Mon-Sun: 8am-9pm EST | US & Canada)</p>
            </div>
            <div className="column">
              <div className="group-title mb-4">Warehouse Location:</div>
              <p className="is-flex mb-2 has-text-white" style={{ alignItems: 'flex-start' }}>
                <img
                  alt="location"
                  src={shopHelpPath(`location.png`)}
                  className="image"
                  style={{ paddingTop: 2 }}
                />
                <span className="ml-2">
                  770 Walnut Creek Drive NW,
                  <br /> Lilburn, GA 30047
                </span>
              </p>
              <p className="is-italic">
                Note: Canadian customers, please call our Customer Service Center for local return
                address.
              </p>
            </div>
            <div className="column">
              <div className="group-title mb-4">Resources</div>
              {Object.keys(StaticContentTitle)
                .filter(
                  (key) =>
                    key !== StaticContentConst.ABOUT_US &&
                    key !== StaticContentConst.APP_PRIVACRY_POLICY
                )
                .map((title: string, index: number) => {
                  return (
                    <p
                      key={index}
                      className="has-text-white has-pointer mb-2"
                      onClick={() => router.push(`/policies/${title}`)}
                    >
                      {(StaticContentTitle as any)[title]}
                    </p>
                  )
                })}
            </div>
          </div>
          <hr className="shophelp-footer__divider" />
          <div className="shophelp-footer__copyright">
            <p>©{new Date().getFullYear()} ShopHelp eCommerce Inc. Corporation number: 1262277-7</p>
          </div>
          <div className="shophelp-footer__go-top is-hidden-desktop has-text-centered">
            <a
              className="shophelp-footer__go-top-button"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              Go to top
              <figure className="image is14x14">
                <img alt="to top" src={shopHelpPath(`arrow_up.svg`)} />
              </figure>
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}

const globalStyle = css`
  .shophelp-footer {
    background-color: var(--gray-9);
    color: var(--white-100);
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    position: relative;
    padding-top: 18px;
    padding-bottom: 16px;

    .group-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
    }

    p,
    a {
      color: var(--gray-5);
    }

    &__go-top {
      text-align: right;

      &-button {
        color: var(--white-100);
        font-size: 15px;
        line-height: 32px;
        margin-right: 16px;
        font-weight: 400;

        .image {
          display: inline-block;
          align-items: center;
          margin-left: 12px;
        }

        &:hover {
          color: var(--white-100);
        }
      }
    }

    &__divider {
      background-color: var(--gray-8);
      margin-top: 16px;
      margin-bottom: 14px;
    }

    &__copyright {
      display: flex;
      justify-content: center;
      text-align: center;

      a {
        color: var(--gray-6);
        font-size: 12px;
        line-height: 24px;
        //margin: 0 auto;
      }
    }

    @media screen and (max-width: 768px) {
      padding-top: 32px;
      padding-bottom: 32px;

      &__copyright {
        flex-direction: column;
        align-items: center;
        //margin-top: 32px;

        a:first-child {
          margin-bottom: 32px;
        }
      }

      .go-top {
        font-size: 15px;
        line-height: 32px;

        .image {
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
`
export default Footer
