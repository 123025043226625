export const ShippingPolicy: React.FC = () => {
  return (
    <>
      <p className="mb-8">
        ShopHelp is a global eCommerce platform that connects customers with stores from around the
        world. Stores may offer a number of different shipping methods depending on the item
        variation and your location and those shipments are under guaranteed delivery service by
        ShopHelp.
      </p>
      <p className="pt-8 mb-6 has-text-weight-semibold" style={{ fontSize: 24 }}>
        ShopHelp Warehouses
      </p>
      <p className="mb-8">
        ShopHelp warehouses are connected with top manufacturers worldwide to bring together the
        best-valued and high-quality products to our customers. Why they are operated with the main
        function of stocking products before distribution to our end customer, these warehouses are
        located in various places within the contiguous United States to receive returned packages
        from ShopHelp’s direct customer and its partner stores’ buyers.
      </p>
      <p className="pt-4 mb-6 has-text-weight-semibold">Standard Shipping</p>
      <p className="mb-8">
        Our primary carrier partner for orders placed within the contiguous United States is USPS.
        To maintain low shipping costs, stores will generally choose an economical shipping option
        based on your and the store&apos;s location and the size of the order. Once the parcel
        reaches your location, it will be handed over to your local carrier for the final leg of
        delivery.
      </p>
      <p className="pt-4 mb-6 has-text-weight-semibold">Delivery time</p>
      <p className="mb-6">
        Once each item in your order is shipped, you&apos;ll receive a shipment notification to your
        account registered email. This email will provide you with the estimated delivery date for
        your item and tracking information.
      </p>
      <p className="mb-6">
        You can also view these estimated dates under each item in your Order History.
      </p>
      <p className="mb-8">
        Orders typically take 3-5 business days to be processed and 7-14 days for delivery. During
        high volume or crisis periods, the processing time may take an additional 2-4 days.
      </p>
      <p className="pt-4 mb-6 has-text-weight-semibold">Late delivery</p>
      <p className="mb-6">
        Each item will also have a maximum delivery date backed by our Money Back Guarantee. We
        calculate a maximum delivery date for every item in case there are delays that are out of
        our control. If you don’t receive your item by the maximum delivery date, open a ticket in
        the app for a full refund.
      </p>
      <p className="mb-8">
        If your item arrives and there&apos;s an issue, please contact our support team through your
        Order History. You can place your request for a return or a refund within 7 days of the
        delivery date.
      </p>
      <p className="pt-4 mb-6 has-text-weight-semibold">Money-Back Guarantee</p>
      <p className="mb-6">
        If your item isn’t delivered by the maximum delivery date, you’re eligible for a full refund
        for that item.
      </p>
      <p>
        To initiate a refund request, go to your Order History and open a ticket for the item in
        question.
      </p>
    </>
  )
}
