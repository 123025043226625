export const AboutUs: React.FC = () => {
  return (
    <>
      <p className="mb-8">
        ShopHelp connects millions of users to merchants in our networks all over the world. We
        focus on ecommerce deals and customer service to exceed our end customer’s expectations when
        it comes to online shopping experience.{' '}
      </p>
      <p className="has-text-weight-semibold mb-6">Ecommerce deals:</p>
      <p className="mb-8">
        ShopHelp runs its own stores and partners with online retailers in delivering best deals to
        customers. We are focusing on giving our worldwide customers access to the widest selection
        of affordable goods at the lowest price with highest guaranteed quality. We do this by
        working directly with suppliers and prioritizing value over brand, packaging and fast
        delivery. You can either shop from our partner stores or directly from ShopHelp, but rest
        assured, best deals stay here at ShopHelp at any time.{' '}
      </p>
      <p className="pt-8 has-text-weight-semibold mb-6">Excellent customer service: </p>
      <p className="mb-6">
        Our #1 priority here at ShopHelp is your happiness. Which means we stand by our product and
        service 100%, no matter what you buy or which stores you deal with. If you have a problem
        with ShopHelp’s deal or with any of our partner stores, we will solve it.{' '}
      </p>{' '}
      <p className="mb-8">
        {' '}
        We embraced the idea of a centralised, single customer care and sales hub to support online
        shopping. By creating an interactive single buyer touchpoint, we deeply understand your
        utmost needs and adapt our product and service to match.{' '}
      </p>
      <p className="pt-8">
        We also design an internal assessment system to qualify all our partner stores through a
        strict and globally standardised requirements, ensuring that their products and fulfillment
        capability meet our commitment to you as an excellent service provider.
      </p>
    </>
  )
}
