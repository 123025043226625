export const PaymentMethods: React.FC = () => {
  return (
    <>
      <p>
        ShopHelp and all its partner stores accept credit, debit, and prepaid cards issued by the
        following networks:
      </p>
      <ul style={{ listStyle: 'disc', marginLeft: '1.5rem' }} className="mb-8">
        <li>Visa</li>
        <li>Master</li>
        <li>Amex</li>
      </ul>
      <p className="mb-8">
        We also accept PayPal - the most popular online payment method worldwide.
      </p>
      <p className="mb-6">
        When you place an order with Paypal, you will be redirected to the PayPal payment page where
        you are required to log on to your Paypal account with your username and password. If Paypal
        does not support your currency, your payment will automatically be charged in US dollars.
      </p>{' '}
      <p> If you have some trouble making a payment, please see some helpful suggestions below:</p>
    </>
  )
}
